<template>
	<v-sheet>
		<Question 
		:disabled="error" 
		:errorMessage="errorMessage" 
		v-for="question in visibleQuestions" 
		:key="question.id" 
		:data="data[question.id]" 
		:poll="pollsByQuestion[question.id]"
		:id="question .id" @update="update">
		</Question>
	</v-sheet>
</template>

<style lang="less">
	.goal-title {
		font-weight: 400;
		font-size: 1.4em;
		margin-left: -15px;
	}
</style>

<script type="text/javascript">
	import Question from "@/components/survey/render/Question"

	export default {
		name: "Questions", 
		props: {
			questions: {type: Array},
			data: {type: Object}, 
			error: {type: Boolean, default: false }, 
			errorMessage: {type: String}
		},
		data: () => {
			return {
			}
		},
		components: {
			Question, 
		},
		computed: {
			visibleQuestions(){
				const self = this;
				return this.questions.filter( a => {
					return self.mwsurveyutilities.visibilityTest( a, this.data )
				})
			}, 
			completionRate(){
				return Math.floor( (this.visibleQuestions.filter( d => this.data[d] ).length / this.visibleQuestions.length)*100 );
			},
			pollsByQuestion(){
				return this.$store.getters.currentPollsByQuestion
			}
		},
		methods: {
			update(id, value){
				this.$emit('update', id, value);
			},
		},
		watch: {
			visibleQuestions: {
				deep: true, 
				handler(nv, ov ){
					var self = this;
					var visibleIds = nv.map( q => q.id );
					if( ov ){
						ov.forEach( q => {
							if( !visibleIds.find( v => v == q.id ) ){
								self.$emit( "update", q.id, "" );
							}
						})
					}
				}
			}
		}
	}
// </script>"