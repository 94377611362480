<template>
	<v-row>
		<v-col>
			<div class="ig-question-wrapper">
				<div class="ig-question-title">
					{{question.title}}
				</div>
				<div class="ig-question-body">
					<p class="ig-question-description">
						{{question.description}}
					</p>
					<v-component :errorMessage="errorMessage" :is="componentName" :disabled="disabled" :question="question" :id="id" :data="data" @update="update" @error="error = true" :poll="poll"></v-component>
				</div>

				<ErrorMessage :trigger="error" @close="error = false" :message="errorMessage"></ErrorMessage>
			</div>
		</v-col>
	</v-row>
</template>

<style lang="less">
	.ig-question-wrapper{
		margin-bottom: 15px;

		.ig-question-title {
			font-weight: 300;
			font-size: 	1.2em;
			margin-bottom: 18px;
		}

	}

</style>

<script type="text/javascript">
	import Vuex from "vuex";

	import ErrorMessage from "@/components/notices/ErrorMessage"

	export default {
		name: "Question", 
		props: {
			id: {}, 
			errorMessage: {},
			data: {}, 
			disabled: {}, 
			poll: {}
		},
		data: () => {
			return {
				error: false
			}
		},
		components: {
			ErrorMessage,
			RadioTiles: () => import("@/components/survey/questionTypes/RadioTiles")
		},
		computed: {
			...Vuex.mapState({
				question(state){
					var id = this.id;
					return state.questions[id];
				}
			}),
			componentName(){
				if( this.question.type == "mwRadioTiles" ){
					return "RadioTiles"
				}
				return "RadioTiles"
			}
		},
		methods: {
			update(data){
				this.$emit( "update", this.id, data )
			}
		}
	}
// </script>"